$(document).ready(function(){
$('.circle-count-1').tooltip({trigger: "click"}); 
$('.circle-count-2').tooltip({trigger: "click"}); 
$('.circle-count-3').tooltip({trigger: "click"}); 
$('.circle-count-4').tooltip({trigger: "click"}); 
$('.circle-count-5').tooltip({trigger: "click"}); 
});


$('.circle-count').on('show.bs.tooltip', function() {
    //Only one tooltip should ever be open at a time
     $('.tooltip').not(this).tooltip('hide'); 
 });

