import './sass/arrows.scss';
import './sass/footer.scss';
import './sass/header.scss';
import './sass/images.scss';
import './sass/main.scss';
import './sass/carousel.scss';

import 'bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import './js/carousel';
import './js/header-burger';
import './js/tooltips';