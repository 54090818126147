$('.owl-carousel').owlCarousel({
      items: 7,
      margin: 0,
      loop: true,
      nav: true,
      center: true,
      dots: true,
      dotsEach: true, 
      slideBy: 1,    
      navText: ["<",">"],
      responsive:{ //Адаптивность. Кол-во выводимых элементов при определенной ширине.
        0:{
            items:2
        },
        600:{
            items:2
        },
        1000:{
            items:7
        }
    }
    });


    
var owl1 = $('.owl-carousel');
owl1.owlCarousel();
// Go to the next item
$('.customNextBtn').click(function() {
    owl1.trigger('next.owl.carousel');
})
// Go to the previous item
$('.customPrevBtn').click(function() {
    owl1.trigger('prev.owl.carousel');
});
